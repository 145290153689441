<template>
    <div class="media-active-container">
        <div class="media-active">
            <div class="media-active__title">
                Я — медійно активний
            </div>
            <form @submit.prevent="submitForm" ref="formMediaActive">
                <div class="media-active__inputs">
                    <div class="media-active__left">
                        <span v-if="errors.date" class="error-label">{{errors.date}}</span>
                        <DatepickerComponent
                            label="Дата заходу"
                            required
                            name="dateEvent"
                            v-model="dateEvent"

                        />
                        <div class="media-active__social">
                            <span v-if="errors.network_id" class="error-label">{{errors.network_id}}</span>
                            <CustomSelectV2
                                :options="socialList"
                                v-model="socialId"
                                required="required"
                                label="Соціальна мережа"
                            />
                        </div>
                        <div class="media-active__like">
                            <span v-if="errors.likes_count_id" class="error-label">{{errors.likes_count_id}}</span>
                            <CustomSelectV2
                                :options="likesList"
                                v-model="likeId"
                                required="required"
                                label="Кількість лайків"
                            />
                        </div>
                    </div>
                    <div class="media-active__right">
                        <div class="media-active__description">
                            <span v-if="errors.description" class="error-label">{{errors.description}}</span>
                            <span class="textarea-label">Опис</span>
                            <textarea
                                name="description"
                                class="input-media"
                                type="text"
                                v-model="description">
                            </textarea>
                        </div>
                    </div>
                </div>
                <div class="media-active__btn">
                    <button class="btn-cancel" type="button" :class="{disableBtn : toggleButton}" @click="clearForm">
                        Скасувати
                    </button>
                    <button class="btn-save" type="submit">
                        Зберегти
                    </button>
                </div>
                <div class="form-popup" v-if="popupStatus" @click="closePopup"></div>
                <div class="popup-container" v-if="popupStatus">
                    <div class="close-popup" @click="closePopup">&#10006;</div>
                    <div class="popup-title">
                        Дякуємо!
                    </div>
                    <div class="popup-description">
                        Форма успішно збережена. <br> Будь ласка, закрийте це вікно, щоб перейти до списку.
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import DatepickerComponent from "../components/ui/DatepickerComponent";
import CustomSelectV2 from "@/components/ui/CustomSelectV2";
import PromotionService from "@/services/PromotionService";

export default {
    name: "FormMediaActive",
    components: {
        DatepickerComponent,
        CustomSelectV2
    },
    data () {
        return {
            dateEvent: null,
            socialList: [],
            socialId: null,
            likesList: [],
            likeId: null,
            toggleButton: true,
            description: '',
            errors: {
                network_id: '',
                likes_count_id: '',
                date: '',
                description: ''
            },
            update: false,
            userId: null,
            popupStatus: false
        }
    },
    watch:{
        userId() {
            this.getEvent()
        },

        socialId(){
            if (this.socialId){
                this.toggleButton = false
            }
        },
        dateEvent(){
            if (this.dateEvent){
                this.toggleButton = false
            }
        }
    },
    methods:{
        async getEventList(){
            let response = await PromotionService.getAllDropdowns()
            for (let network in response.network_id){
                this.socialList.push(
                    {"value": network, 'label': response.network_id[network]}
                )
            }
            for (let likes in response.like_count_id){
                this.likesList.push(
                    {"value": likes, 'label': response.like_count_id[likes]}
                )
            }
        },
        async submitForm(){
            let formData = new FormData(this.$refs.formMediaActive);
            let date;

            if (typeof formData.get('dateEvent') === 'string'){
                date = this.convertDate(new Date(formData.get('dateEvent')))
            } else {
                date = this.dateEvent
            }

            formData.delete('dateEvent')
            formData.append('date', date)
            formData.append('network_id', this.socialId)
            formData.append('likes_count_id', this.likeId)

            if (!this.update){
                let resp = await PromotionService.uploadProjectForm(formData, 3).catch(this.handleError)

                if (resp && resp.status === "success"){
                    this.clearForm()
                    this.errors = {}
                    this.popupStatus = true
                }
            }

            if (this.update){
                let resp = await PromotionService.uploadEditProject(formData, +this.$route.params.id).catch(this.handleError)

                if (resp){
                    this.clearForm()
                    this.errors = {}
                    this.popupStatus = true
                }
            }
        },
        handleError(resp){
            if (resp.statusCode === 422) {
                this.errors = resp.data
            }
        },
        clearForm() {
            if (!this.toggleButton){
                this.socialId = null
                this.description = ''
                this.likeId = null
                this.dateEvent = null
                this.toggleButton = true
            }
        },
        convertDate(date){
            let datum = Date.parse(date);
            return datum/1000;
        },
        async getUserId(){
            let resp = await PromotionService.getUserId().catch(this.handleError)
            this.userId = resp
        },
        async getEvent() {
            if (this.update){
                let resp = await PromotionService.getPromotionList(this.userId, 3).catch(this.handleError)
                let event = resp.find(item => {
                    return +item.id === +this.$route.params.id
                })

                this.description = event.description
                this.dateEvent = event.date
                this.socialId = String(event.network_id)
                this.likeId = String(event.likes_count_id)
            }
        },
        closePopup() {
            this.popupStatus = false
            this.$router.push('/encouragement/3')
        }
    },
    created() {
        this.getEventList()

        if (this.$route.name === 'media-active-update'){
            this.update = true
        }

        this.getUserId()
    }
}
</script>

<style scoped lang="less">
.media-active-container{
    background-color: #f5f5f5;;
    width: 100%;
    min-height: 100vh;
    float: right;
    display: flex;
    justify-content: center;
    align-items: baseline;

    @media (max-width: 992px){
        width: 100%;
    }
    .media-active{
        margin: 36px 20px;
        padding: 0 40px;
        width: 1200px;
        min-height: 525px;
        border-radius: 10px;
        border: solid 1px #cdd4e0;
        background-color: #fff;
        @media (max-width: 992px){
            margin: 36px 0;
        }
        &__title{
            font-family: @secondFontFamily;
            margin-top: 50px;
            font-weight: 500;
            font-size: 24px;
            color: #1a2744;
            display: flex;
            justify-content: center;
        }
        &__inputs{
            margin-top: 50px;
            display: flex;
            justify-content: space-between;

            @media (max-width: 768px){
                flex-direction: column;
            }

            .error-label{
                font-size: 12px;
                color: #ffc900;
                height: 0;
                display: block;
                position: absolute;
                right: 45px;

                @media (max-width: 768px){
                    position: relative;
                    left: 140px;
                }
            }

            .media-active__left{
                padding-right: 40px;

                @media (max-width: 768px){
                    padding-right: 0;
                }
            }
            .media-active__left, .media-active__right{
                width: 50%;
                position: relative;

                @media (max-width: 768px){
                    width: 100%;
                }

                .media-active__social, .media-active__like{
                    margin-top: 20px;
                }
                .select-label {
                    font-size: 12px;
                    color: #1a2744;
                    position: relative;
                    left: 20px;
                    bottom: 5px;
                }

                .media-active__description{
                    margin-top: 5px;

                    @media (max-width: 768px){
                        margin-top: 20px;
                    }

                    .textarea-label {
                        font-size: 12px;
                        color: #1a2744;
                        position: relative;
                        left: 20px;
                        bottom: 5px;
                    }
                    .input-media{
                        display: block;
                        outline: none;
                        width: 100%;
                        height: 235px;
                        border: 1px solid #cdd4e0;
                        border-radius: 10px;
                        font-size: 16px;
                        font-weight: 500;
                        padding: 20px;
                        resize: none;
                        &:focus{
                            border-color: #ffc900;
                        }
                    }
                }
            }
        }
        &__btn{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 40px;

            @media (max-width: 768px){
                margin-bottom: 40px;
            }
            .btn-cancel, .btn-save{
                font-family: @secondFontFamily;
                font-size: 18px;
                font-weight: 600;
                line-height: 1.33;
                width: 220px;
                height: 50px;
                background-color: #1a2744;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                margin-right: 11px;
                cursor: pointer;
                box-shadow: 0 24px 24px -14px rgba(26, 39, 68, 0.35);

                @media (max-width: 480px){
                    width: 50%;
                }

            }
            .disableBtn{
                opacity: 0.4;
                cursor: auto;
                box-shadow: none;
            }
        }
    }
    .form-popup{
        position: fixed;
        background-color: #1a2744;
        width: 200vw;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0.6;
        z-index: 1;
    }
    .popup-container {
        z-index: 5;
        position: fixed;
        top: 50%;
        right: 25%;
        width: 610px;
        height: 196px;
        border-radius: 10px;
        box-shadow: 0 24px 24px -14px rgba(157, 163, 174, 0.1);
        border: solid 1px #cdd4e0;
        background-color: #f5f5f5;
        padding: 31px 74px 48px 80px;

        .close-popup {
            position: absolute;
            cursor: pointer;
            right: 14px;
            top: 14px;
        }

        .popup-title {
            font-family: @secondFontFamily;
            font-size: 24px;
            font-weight: 500;
            line-height: 1.5;
            letter-spacing: -0.17px;
            color: #1a2744;
            position: relative;

            &:before {
                content: url("../assets/images/info.svg");
                position: absolute;
                left: -40px;
                top: 2px;
            }
        }

        .popup-description {
            margin-top: 9px;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.5;
            letter-spacing: -0.11px;
            color: #1a2744;
        }
    }
    ::v-deep .vc-popover-caret{
        left: 87%!important;
    }
    ::v-deep .vc-popover-content{
        position: relative!important;
        left: 100%!important;
    }
    ::v-deep .form-input input[name="dateEvent"]{
        cursor: pointer!important;
    }
}
</style>
